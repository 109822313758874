import Globals from "../utils/globalUtils";
import { hideLoader, showLoader } from "../utils/loader";
const CHANNEL_CATEGORIES = {
  popular: "popular_channels",
  republican: "republican_channels",
  center: "center_channels",
  democrat: "democrat_channels",
};

const VIDEO_CATEGORIES = {
  hot: "hot_videos",
  recent: "recent_videos",
  trending: "trending_videos",
  live: "live_now",
  republican: "republican_videos",
  center: "center_videos",
  democrat: "democrat_videos",
};

const getVideoURL = (video) => {
  return `/video/${video["yt_id"]}/`;
};

const getChannelURL = (channel) => {
  return `/channel/${channel["yt_id"]}/`;
};

const fetchVideo = (videoId) => {
  return Globals.sendRequest({
    url: `/api/videos/${videoId}/`,
    method: "GET",
  });
};

const fetchChannel = (channelId) => {
  return Globals.sendRequest({
    url: `/api/channels/${channelId}/`,
    method: "GET",
  }).catch((err) => {
    return err
  });
};

const fetchFeaturedVideo = () => {
  return Globals.sendRequest({
    url: `/api/videos/featured/`,
    method: "GET",
  });
};

const fetchLiveVideos = ({ count = 2 }) => {
  const queryParams = { count };
  return Globals.sendRequest({
    url: `/api/videos/live/`,
    method: "GET",
    queryParams: queryParams,
  });
};

const fetchChannels = ({ filters = [], position = null }) => {
  showLoader();
  let queryParams = {};
  if (filters && Object.keys(filters).length > 0)
    queryParams = Object.assign(queryParams, filters);
  if (position) queryParams["pos"] = position;
  return Globals.sendRequest({
    url: "/api/v2/channels/",
    queryParams: queryParams,
  }).finally(() => {
    hideLoader();
  });
};

const fetchVideos = ({
  filters = {},
  position = null,
  orderBy = null,
  order = null,
}) => {
  showLoader();
  let queryParams = {};
  if (orderBy && order) {
    queryParams = {
      order_by: orderBy,
      order: order,
    };
  }
  if (filters && Object.keys(filters).length > 0)
    queryParams = Object.assign(queryParams, filters);
  if (position) queryParams["pos"] = position;

  return Globals.sendRequest({
    url: "/api/videos/",
    queryParams: queryParams,
  }).finally(() => {
    hideLoader();
  });
};

const fetchChannelVideos = ({ channel }) => {
  let queryParams = {};
  return Globals.sendRequest({
    url: `/api/channels/${channel}/videos/`,
    method: "GET",
    queryParams: queryParams,
  });
};

const fetchChannelFollowers = ({ channel }) => {
  let queryParams = {};
  return Globals.sendRequest({
    url: `/api/channels/${channel}/followers/`,
    method: "GET",
    queryParams: queryParams,
  });
};

const followChannel = (channelID, access, value) => {
  return Globals.sendRequest({
    url: `/api/livestream/following/follow/`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
    body: JSON.stringify(
     { 
      value: value,
      channel_id: channelID,
    }
    ),
  });
};

const unfollowChannel = (channelId) => {
  return Globals.sendRequest({
    url: `/api/channels/${channelId}/unfollow/`,
    method: "POST",
  });
};

const fetchRecommendations = ({ videoId, recommendationType }) => {
  return Globals.sendRequest({
    url: `/api/videos/${videoId}/${recommendationType}/`,
    method: "GET",
  });
};

const fetchCategoryContent = (type) => {
  const queryParams = {};
  if (type) queryParams["type"] = type;
  return Globals.sendRequest({
    url: "/api/categories/",
    queryParams: queryParams,
  });
};

const buildVideoContent = (setContentCallback) => {
  const videoContent = {};
  Object.keys(VIDEO_CATEGORIES).forEach((category) => {
    fetchCategoryContent(VIDEO_CATEGORIES[category]).then((content) => {
      videoContent[category] = content;
      setContentCallback(Globals.deepCopy(videoContent));
    });
  });
};

const buildChannelContent = (setContentCallback) => {
  const channelContent = {};
  Object.keys(CHANNEL_CATEGORIES).forEach((category) => {
    fetchCategoryContent(CHANNEL_CATEGORIES[category]).then((content) => {
      channelContent[category] = content;
      setContentCallback(Globals.deepCopy(channelContent));
    });
  });
};

const searchContent = (query) => {
  return Globals.sendRequest({
    url: "/api/livestream/search/",
    queryParams: { q: query },
    method: "GET",
  });
};

const fetchRecommendedVideosBasedOnQuery = (query) => {
  return Globals.sendRequest({
    url: "/api/recommended-videos/",
    queryParams: { q: query },
    method: "GET",
  });
}

const VideoService = {
  fetchChannels,
  fetchVideos,
  fetchRecommendations,
  fetchRecommendedVideosBasedOnQuery,
  fetchVideo,
  fetchChannel,
  fetchChannelVideos,
  fetchChannelFollowers,
  fetchFeaturedVideo,
  fetchLiveVideos,
  fetchCategoryContent,
  buildVideoContent,
  buildChannelContent,
  searchContent,
  followChannel,
  unfollowChannel,
  getVideoURL,
  getChannelURL,
  CHANNEL_CATEGORIES,
  VIDEO_CATEGORIES,
};

export default VideoService;
