import { actionTypes } from "../actions/notifications";
const {
    NOTIFICATION_LIST,
} = actionTypes;

const initialState = {
    notificationList: [],
    // isLoggedIn: null,
};

export default function streamer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_LIST:
            return { ...state, notificationList: action.notificationList };
        default:
            return state;
    }
}
