const showLoader = () => {
  const overlayElem = document.getElementById("loader-politix");
  const baseElem = document.getElementById("baseWrapper");
  if (overlayElem) {
    overlayElem.style.visibility = "unset";
  }
  if (baseElem) {
    baseElem.style.opacity = "0.5";
    baseElem.style.pointerEvents = "none";
  }
};

const hideLoader = () => {
  const overlayElem = document.getElementById("loader-politix");
  const baseElem = document.getElementById("baseWrapper");
  if (overlayElem) {
    const timeout = setTimeout(() => {
      overlayElem.style.visibility = "hidden";
      clearTimeout(timeout);
    }, 200);
  }
  if (baseElem) {
    baseElem.style.opacity = "1";
    baseElem.style.pointerEvents = "unset";
  }
};

// exporting both the functions
export { showLoader, hideLoader };
