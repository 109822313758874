import { Link } from "react-router-dom";
import styles from "./AppQuiz.module.scss";

const AppQuiz = ({ closeQuiz }) => {
  const closeModal = () => {
    closeQuiz();
  };

  return (
    <div className={styles.appQuizModalOverlay}>
      <div className={styles.appQuizModal}>
        <div className={styles.appQuizModalHeading}>
          <h2>
            Thanks for Signing Up <br /> for Politix!
          </h2>
          <p>Fill out your Political Profile on your User Page</p>
        </div>
        {/* <PoliticalQuiz isQuizModal /> */}
        <div className={styles.continueBtnWrapper}>
          <Link to="/profile/me/settings" onClick={closeModal}>
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppQuiz;
