import React from "react";
import styles from "./Container.module.scss";

const Container = (props) => {
  const { children, className = "" } = props;

  return (
    <div className={`${styles.mainContainer} ${className}`}>{children}</div>
  );
};

export default Container;
