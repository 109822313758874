import Globals from "../utils/globalUtils";
import { hideLoader, showLoader } from "../utils/loader";

const getUserProfileURL = (user) => {
  return `/profile/user/${user["username"]}`;
};

const register = (username, password, email, referral_code) => {
  const data = {
    email,
    username,
    password,
    referral_code,
  };
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/sign-up/",
      data: data,
      method: "POST",
    })
      .then((response) => {
        if (response["access"]) {
          localStorage.setItem("user", JSON.stringify(response));
        }
        fetchCurrentUserProfile()
          .then((profile) => {
            window.hj("identify", profile.id, {
              email: profile.email,
            });
            resolve(Object.assign({}, profile, response));
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const activateAccount = (token) => {
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/activate/",
      data: { token: token },
      method: "POST",
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = ({
  email,
  password,
  logintype = "email",
  username,
  auth_token,
  access_token_key,
  access_token_secret,
}) => {
  const data = {
    logintype,
    email: email?.toLowerCase() || undefined,
    password,
    username,
    auth_token,
    access_token_key,
    access_token_secret,
  };
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/sign-in/",
      data: data,
      method: "POST",
    })
      .then((response) => {
        if (response.access) {
          localStorage.setItem("user", JSON.stringify(response));
        }
        fetchCurrentUserProfile()
          .then((profile) => {
            window.hj("identify", profile.id, {
              email: profile.email,
            });
            resolve(Object.assign({}, profile, response));
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const verifyAuthToken = function () {
  const user = getCurrentUser();
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/verify-token/",
      method: "POST",
      data: { token: user["access"] },
    })
      .then((response) => {
        fetchCurrentUserProfile()
          .then((profile) => {
            resolve(Object.assign({}, profile, response));
          })
          .catch((error) => {
            reject(error);
          });
        // resolve(response);
      })
      .catch((error) => {
        logout();
        reject(error);
      });
  });
};

const updateLocalUserProfile = (updatedProfile) => {
  const user = getCurrentUser();
  const updatedUser = Object.assign(user, updatedProfile);
  localStorage.setItem("user", JSON.stringify(updatedUser));
  return updatedUser;
};

const fetchCurrentUserProfile = () => {
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/me/",
      method: "GET",
    })
      .then((profile) => {
        updateLocalUserProfile(profile);
        resolve(profile);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const uploadAvatar = (image) => {
  showLoader();
  const formData = new FormData();
  formData.append("avatar", image);
  return new Promise((resolve, reject) => {
    Globals.sendRequest({
      url: "/api/users/me/",
      method: "POST",
      data: formData,
    })
      .then((profile) => {
        profile = updateLocalUserProfile(profile);
        hideLoader();
        resolve(profile);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchEmailSettings = () => {
  return Globals.sendRequest({
    url: "/api/users/email-settings/",
    method: "GET",
  });
};

const updateEmailSettings = (settings) => {
  return Globals.sendRequest({
    url: "/api/users/email-settings/",
    method: "PUT",
    data: settings,
  });
};

const fetchPoliticalProfile = (username) => {
  const queryParams = {};
  if (username) queryParams["user"] = username;
  return Globals.sendRequest({
    url: "/api/users/political-profile/",
    method: "GET",
    queryParams: queryParams,
  });
};

const requestResetPassword = (email) => {
  return Globals.sendRequest({
    url: "/api/users/reset-password-request/",
    method: "POST",
    data: { email: email },
  });
};

const resetPassword = (email, password, token) => {
  return Globals.sendRequest({
    url: "/api/users/reset-password-commit/",
    method: "POST",
    data: { email: email, password: password, token: token },
  });
};

const changePassword = (newPassword, currentPassword) => {
  return Globals.sendRequest({
    url: "/api/users/change-password/",
    method: "POST",
    data: { new_password: newPassword, current_password: currentPassword },
  });
};

const contactUs = (email, subject, message) => {
  return Globals.sendRequest({
    url: "/api/users/contact/",
    method: "POST",
    data: { email: email, subject: subject, message: message },
  });
};

const updatePoliticalProfile = (profile) => {
  return Globals.sendRequest({
    url: "/api/users/political-profile/",
    method: "PUT",
    data: profile,
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const fetchFriends = (userId) => {
  return Globals.sendRequest({
    url: `/api/users/friends/${userId}/`,
    method: "GET",
  });
};

const unFriendUser = (userId) => {
  return Globals.sendRequest({
    url: `/api/users/friends/${userId}/`,
    method: "PUT",
  });
};

const fetchFriendRequests = () => {
  return Globals.sendRequest({
    url: `/api/users/friends/requests/`,
    method: "GET",
  });
};

const sendFriendRequest = (username) => {
  showLoader();
  return Globals.sendRequest({
    url: `/api/users/friends/requests/${username}/`,
    method: "POST",
  }).finally(() => {
    hideLoader();
  });
};

const acceptFriendRequest = (requestId) => {
  return Globals.sendRequest({
    url: `/api/users/friends/requests/action/${requestId}/`,
    method: "PUT",
  });
};

const rejectFriendRequest = (requestId) => {
  return Globals.sendRequest({
    url: `/api/users/friends/requests/action/${requestId}/`,
    method: "DELETE",
  });
};

const fetchUser = (username) => {
  showLoader();
  return Globals.sendRequest({
    url: `/api/users/profile/${username}/`,
    method: "GET",
  }).finally(() => {
    hideLoader();
  });
};

const fetchFollowingChannels = (username) => {
  showLoader();
  return Globals.sendRequest({
    url: `/api/users/profile/${username}/channels/`,
    method: "GET",
  }).finally(() => {
    hideLoader();
  });
};

const sendVerificationEmail = (email) => {
  return Globals.sendRequest({
    url: "/api/users/reverify/",
    method: "POST",
    data: { email: email },
  });
};

const getNotifications = () => {
  return Globals.sendRequest({
    url: "api/livestream/notification",
    method: "GET",
  })
}

const getPaymentIntent = (channelId) => {
  showLoader();
  return Globals.sendRequest({
    url: "/api/payments/create-subscription/",
    method: "POST",
    data: {
      "channel_id": channelId
    }
  }).finally(() => {
    hideLoader();
  }).catch((err) => {
    throw err;
  });
}

const getSetupPaymentIntent = () => {
  showLoader();
  return Globals.sendRequest({
    url: "/api/payments/send-setup-intent/",
    method: "POST",
    data: {
      // "customerId": "cus_N67KXkS4nIMvYa"
    }
  }).finally(() => {
    hideLoader();
  }).catch((err) => {
    throw err;
  });
}

const getSubscribedChannels = () => {
  return Globals.sendRequest({
    url: "/api/livestream/subscriber_channels/",
    method: "GET"
  }).catch((err) => {
    throw err;
  });
}

const deleteSubscription = (channelId = null) => {
  return Globals.sendRequest({
    url: `/api/livestream/subscriber_channels/`,
    method: "DELETE",
    data: {
      "channel_id": (channelId).toString()
    }
  }).catch((err) => {
    throw err;
  });
}

const getPaymentMethods = () => {
  return Globals.sendRequest({
    url: '/api/payments/customer-payment-details/',
    method: "GET",
  }).catch((err) => {
    throw err;
  });
}

const deletePaymentMethod = (paymentMethodId) => {
  return Globals.sendRequest({
    url: `/api/payments/customer-payment-details/`,
    method: "DELETE",
    data: {
      "payment_method": (paymentMethodId).toString()
    }
  }).catch((err) => {
    throw err;
  });
}

const setAsDefault = (paymentMethodId) => {
  return Globals.sendRequest({
    url: '/api/payments/customer-payment-details/',
    method: "PATCH",
    data: {
      "payment_method": (paymentMethodId).toString()
    }
  }).catch((err) => {
    throw err;
  });
}

const getTransactions = () => {
  return Globals.sendRequest({
    url: '/api/payments/transactions/',
    method: "GET",
  }).catch((err) => {
    throw err;
  });
}

const validateConnectAccount = (channelId = '') => {
  return Globals.sendRequest({
    url: `/api/payments/connect?q=${channelId}`,
    method: "GET",
  }).catch((err) => {
    throw err;
  });
}

const getConnectAccountUrl = (channelId = '', returnUrl) => {
  return Globals.sendRequest({
    url: `/api/payments/get-onboard-link/?q=${channelId}&return_url=${returnUrl}`,
    method: "GET",
  }).catch((err) => {
    throw err;
  });
}

const getLoginLink = (channelId = '') => {
  return Globals.sendRequest({
    url: `/api/payments/get-login-link?q=${channelId}`,
    method: 'GET',
  }).catch((err) => {
    throw err;
  });
}

const requestPayout = (channelId = '') => {
  return Globals.sendRequest({
    url: '/api/payments/connect/',
    method: 'POST',
    data: {
      "channel_id": channelId
    }
  }).catch((err) => {
    throw err;
  });

}

export default {
  register,
  login,
  logout,
  requestResetPassword,
  resetPassword,
  changePassword,
  contactUs,
  activateAccount,
  getCurrentUser,
  verifyAuthToken,
  uploadAvatar,
  fetchEmailSettings,
  updateEmailSettings,
  fetchPoliticalProfile,
  updatePoliticalProfile,
  fetchFriends,
  fetchUser,
  getUserProfileURL,
  fetchCurrentUserProfile,
  updateLocalUserProfile,
  fetchFollowingChannels,
  fetchFriendRequests,
  sendFriendRequest,
  unFriendUser,
  acceptFriendRequest,
  rejectFriendRequest,
  sendVerificationEmail,
  getNotifications,
  getPaymentIntent,
  getSetupPaymentIntent,
  getSubscribedChannels,
  deleteSubscription,
  getPaymentMethods,
  deletePaymentMethod,
  setAsDefault,
  getTransactions,
  validateConnectAccount,
  getConnectAccountUrl,
  getLoginLink,
  requestPayout
  // passwordReset,
};
