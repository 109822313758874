import generateActionTypes from "../../utils/generateActionTypes";

export const actionTypes = generateActionTypes(
    "NOTIFICATION_LIST",
);

// user profile data
export function notificationsList(notificationList) {
    return (dispatch) => {
        return dispatch({ type: actionTypes.NOTIFICATION_LIST, notificationList });
    };
}