import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

const RedirectHandler = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    // Extract the authorization code from the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const twitterToken = searchParams.get("oauth_verifier");
//     curl --location --request POST 'https://politix.tv/api/users/socials/' \
// --header 'Authorization: Bearer ' \
// --header 'Content-Type: application/json' \
// --data '{
//     "platform": "X",
//     "code": "{{OAUTH_VERIFIER}}"
// }'
    if(twitterToken){
      fetch(`https://politix.tv/api/users/socials/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        },
        body: JSON.stringify({
          platform: "X",
          code: { twitterToken },
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          navigate.push("/");
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }

    if (code) {
      fetch(`https://politix.tv/api/users/me/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`, // ${TOKEN_VALUE}
        },
        body: JSON.stringify({
            "platform": 'facebook',
            "code": {code}
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          navigate.push("/");
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  }, [location.search, navigate]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
}

export default RedirectHandler;
