import { actionTypes } from "../actions/streamer";
const {
    CHANNEL_LIST,
    CHANNEL_ONBOARDING,
    CHANNEL_DETAILS,
    STREAM_DATA
} = actionTypes;

const initialState = {
    channelLists: [],
    channelOnboarding: {
        quiz: [
            {
                "text": "How would you describe your political affiliation?",
                "sections": [
                    "Socialist",
                    "Democrat",
                    "Centrist",
                    "Republican",
                    "Libertarian"
                ],
                "property": "political_affiliation",
                "isOpened": true
            },
            {
                "text": "Should national borders be more open or closed?",
                "sections": [
                    "Open",
                    "Status Quo",
                    "Closed"
                ],
                "property": "immigrations",
                "isOpened": false
            },
            {
                "text": "Do you value security or privacy more?",
                "sections": [
                    "Security",
                    "Neutral",
                    "Privacy"
                ],
                "property": "privacy_or_security",
                "isOpened": false
            },
            {
                "text": "Should marijuana be federally legalized?",
                "sections": [
                    "Recreational",
                    "Medical",
                    "Not Sure",
                    "Leaning No",
                    "Never"
                ],
                "property": "marijuana",
                "isOpened": false
            },
            {
                "text": "Should guns be more or less restricted?",
                "sections": [
                    "More",
                    "Status Quo",
                    "Less"
                ],
                "property": "guns",
                "isOpened": false
            },
            {
                "text": "Is the society or individual more important?",
                "sections": [
                    "Society",
                    "Not Sure",
                    "Individual"
                ],
                "property": "common_good_or_freedom",
                "isOpened": false
            }
        ]
    },
    channelDetails: {},
    streamerData: {
        name: ''
    }
    // isLoggedIn: null,
};

export default function streamer(state = initialState, action) {
    switch (action.type) {
        case CHANNEL_LIST:
            return { ...state, channelLists: action.channels };
        case CHANNEL_ONBOARDING:
            return { ...state, channelOnboarding: { ...state.channelOnboarding, ...action.channelDetails } };
        case STREAM_DATA:
            return { ...state, streamerData: { ...action.streamData } };
        case CHANNEL_DETAILS:
            return { ...state, channelDetails: { ...action.channelDetails } };
        default:
            return state;
    }
}
