import React, { useRef, useEffect, forwardRef } from "react";
import VideoService from "../../services/videos";
import SearchResult from "./SearchResult/SearchResult";
import { useNavigate } from "react-router-dom";
import "../../assets/css/components/search-drowpdown.scss";

const SearchDropdown = forwardRef((props, ref) => {
  const [searchText, setSearchText] = React.useState("");
  const [searchResults, setResults] = React.useState({
    channels: [],
    videos: [],
    people: [],
  });
  const history = useNavigate();

  useEffect(() => {
    const getResult = async () => {
      await VideoService.searchContent(searchText, true).then((response) => {
        setResults({
          channels: response["Channel Results"],
          videos: response["Recordings"],
        });
      });
    };
    if (searchText.length) getResult();
  }, [searchText]);

  const onTextChange = async (query) => {
    setSearchText(query);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history("/search?q=" + searchText);
  };

  return (
    <div className="searchContainer">
      <div className="searchWrapper">
        <label htmlFor="search">
          <i className="fas fa-search" />
        </label>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            id="search"
            type="text"
            placeholder="Search"
            value={searchText}
            ref={ref}
            onChange={(e) => onTextChange(e.target.value)}
          />
        </form>
      </div>
    </div>
  );
});

export default SearchDropdown;
