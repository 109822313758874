import Globals from "../utils/globalUtils";
import { hideLoader, showLoader } from "../utils/loader";

const activateUser = () => {
  return Globals.sendRequest({
    url: "/api/users/activate/",
    method: "POST",
  });
};

const contactPolitix = () => {
  return Globals.sendRequest({
    url: "/api/users/contact/",
    method: "POST",
    data: {},
  });
};

const changePassword = (current_password, new_password) => {
  return Globals.sendRequest({
    url: "/api/users/change-password/",
    method: "POST",
    data: {
      "current_password": current_password,
      "new_password": new_password,
    },
  });
};

const  getUserData = (access) => {
  return Globals.sendRequest({
    url: "/api/users/me/",
    method: "GET",
    Authorization: `Bearer ${access}`, 
  });
};

const getStreamerStatus = (access) => {
  return Globals.sendRequest({
    url: "/api/users/streamer/",
    method: "GET",
    Authorization: `Bearer ${access}`,
  });
};

const getPoliticalProfile = (access) => {
  return Globals.sendRequest({
    url: "/api/users/political-profile",
    method: "GET",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
  });
}


const UsersService = {
  getUserData,
  getStreamerStatus,
  getPoliticalProfile
};

export default UsersService;
