import Globals from "../utils/globalUtils";
import React, { useEffect, useState } from "react";

const getChannel = (channelID) => {
  return Globals.sendRequest({
    url: `/api/livestream/channel/?q=${channelID}`,
    method: "GET",
  });
};

const getLivestream = (channelID) => {
  return Globals.sendRequest({
    url: `/api/livestream/stream/?q=${channelID}`,
    method: "GET",
  });
};

const getPopularCategories = () => {
  return Globals.sendRequest({
    url: `/api/livestream/content/tags`,
    method: "GET",
  });
};

// asset_id: "089b9fa0-a508-469a-a6c4-bc2b4917ff3a";
// avatar: "https://politix.tv/media/avatar/default_pfp.jpg";
// channel: 156;
// channel_name: "Christian's Channel";
// date: "2024-03-11T16:14:44.411603Z";
// description: "testing123";
// duration: null;
// paywall: false;
// playback_id: "089bwjf8h2q27s7a";
// processed: true;
// tags: [];
// thumbnail: "https://politix.tv/ipfs/QmVVCKuYUAFLHVBvFuidtqz73WjYoZYVDRhSzGsez1tXAE";
// title: "testing";
// upload: true;
const getRecordings = () => {
  return Globals.sendRequest({
    url:  `/api/livestream/content/recordings/?limit=25&offset=25`,
    method: "GET",
  });
};

const getPopularStreamers = () => {
  return Globals.sendRequest({
    url: `/api/livestream/content/followed`,
    method: "GET",
  });
};

const getFollowingRecordings = (access) => {
  return Globals.sendRequest({
    url: `/api/livestream/following/recordings/`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
  });
};

const getFollowingChannels = (access) => {
  return Globals.sendRequest({
    url: `/api/livestream/following/channels/`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
  });
};

const getLiveChannels = () => {
  return Globals.sendRequest({
    url: `/api/livestream/content/live/`,
    method: "GET",
  });
};

const postFollow = (channelID, access, val) => {
  console.log(channelID, val);
  return Globals.sendRequest({
    url: `api/livestream/following/follow/`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${access}`, 
      "Content-Type": "application/json", 
    },
    body: JSON.stringify({
      value: val,
      channel_id: channelID,
    }),
  });
};

const getSimilarPerspectives = (channelID) => {
  return Globals.sendRequest({
    url: `api/livestream/content/similar/?q=${channelID}`,
    method: "GET",
  });
}

const getDifferentPerspectives = (channelID) => {
  return Globals.sendRequest({
    url: `api/livestream/content/different/?q=${channelID}`,
    method: "GET",
  });
};

const getUserPerms = (channelID, access) => {
    return Globals.sendRequest({
      url: `/api/livestream/perms/?q=${channelID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
      },
    });
}


const getFollowingStatus = (channelID, access) => {
  return Globals.sendRequest({
    url: `/api/livestream/following/status/?q=${channelID}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
  });
};

const LivestreamService = {
  getChannel,
  getLivestream,
  getRecordings,
  getLiveChannels,
  getFollowingRecordings,
  getFollowingChannels,
  getPopularCategories,
  getPopularStreamers,
  postFollow,
  getSimilarPerspectives,
  getDifferentPerspectives,
  getUserPerms,
  getFollowingStatus,
};

export default LivestreamService;
