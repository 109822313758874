const ACTIONS = {
    SET_USER: 'SET_USER_PROFILE',
};

const Reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_USER:
            return {
                ...state,
                userProfile: action.payload
            };
        default:
            return state;
    }
};

export {ACTIONS};
export default Reducer;
