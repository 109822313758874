import moment from "moment-timezone";
import axios from "axios";
const DEFAULT_LOCALE = "en-US";
const DEFAULT_TIMEZONE = moment.tz.guess() || "America/Newyork";
const DEFAULT_FORMAT = "hh: mm A";

const formatDate = (date) => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (today.getTime() <= date.getTime()) return "Today";

  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  if (yesterday.getTime() <= date.getTime()) return "Yesterday";

  const thisWeek = new Date();
  thisWeek.setDate(today.getDate() - 7);
  thisWeek.setHours(0, 0, 0, 0);
  if (thisWeek.getTime() <= date.getTime()) return "This Week";
  const thisMonth = new Date();
  thisMonth.setDate(today.getDate() - 30);
  thisMonth.setHours(0, 0, 0, 0);
  if (thisMonth.getTime() <= date.getTime()) return "This Month";

  const paddedMonth = ("0" + (date.getMonth() + 1)).slice(-2);
  const paddedDay = ("0" + date.getDate()).slice(-2);
  return `${paddedMonth}/${paddedDay}/${date.getFullYear()}`;
};

const formatDateWithLastUploaded = (date) => {
  const getTimeFormat = moment(date)
    // .tz(DEFAULT_TIMEZONE)
    .locale(DEFAULT_LOCALE)
    .fromNow();
  // commented as not required in the current requirement
  // const dateFormat = moment(date)
  //   .tz(DEFAULT_TIMEZONE)
  //   .locale(DEFAULT_LOCALE)
  //   .format(DEFAULT_FORMAT);
  return `${getTimeFormat}`;
};

const deepCopy = (payload) => {
  try {
    return JSON.parse(JSON.stringify(payload));
  } catch (e) {
    throw e;
  }
};

const getAuthToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user["access"] : null;
};

const getBaseURL = () => {
  /** Function to get the base url for API request */
  let baseUrl;
  if (process.env.NODE_ENV === "development") {
    baseUrl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      `:8000${process.env.REACT_APP_SERVER_SUFFIX || ""}`;
  } else {
    if (!window.location.origin) {
      window.location.origin =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "");
    }
    baseUrl =
      window.location.origin + `${process.env.REACT_APP_SERVER_SUFFIX || ""}`;
  }
  return "https://politix.tv/";
};

const getQueryParams = (params) => {
  if (!params) {
    return "";
  }
  let parameters = Object.keys(params);
  let queryParam = "?";
  parameters.forEach((parameter, index) => {
    if (index !== 0) {
      queryParam += "&";
    }
    queryParam += `${parameter}=${encodeURIComponent(params[parameter])}`;
  });
  return queryParam;
};

const sendRequest = ({
  url = null,
  method = null,
  data = null,
  headers = null,
  queryParams = null,
  responseType = "json",
}) => {
  const authToken = getAuthToken();
  let requestHeaders = deepCopy({
    "Content-Type": "application/json; charset=utf-8;",
  });
  if (authToken) requestHeaders["Authorization"] = `Bearer ${authToken}`;
  requestHeaders = {
    ...requestHeaders,
    ...headers,
  };
  let queryString = getQueryParams(queryParams);
  if (queryString.length > 0) {
    url += queryString;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      baseURL: getBaseURL(),
      method: method,
      data: data,
      headers: requestHeaders,
      responseType: responseType,
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        reject(error.response.data);
      } else reject(error);
    });
  });
};

const Globals = {
  sendRequest,
  deepCopy,
  getBaseURL,
  formatDate,
  formatDateWithLastUploaded,
};

export default Globals;
