import React, { createContext, useState, useEffect } from "react";

// Create the context
export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  // Get dark mode value from localStorage or default to false
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  });

  // Update localStorage when darkMode changes
  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
        console.log("CHANGE");

    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
