import generateActionTypes from "../../utils/generateActionTypes";

export const actionTypes = generateActionTypes(
    "CHANNEL_LIST",
    "CHANNEL_ONBOARDING",
    "CHANNEL_DETAILS",
    "STREAM_DATA"
);

// user profile data
export function channelLists(channels) {
    return (dispatch) => {
        return dispatch({ type: actionTypes.CHANNEL_LIST, channels });
    };
}

// channel onboarding step 1 
export function channelOnboarding(channelDetails) {
    return (dispatch) => {
        return dispatch({ type: actionTypes.CHANNEL_ONBOARDING, channelDetails });
    };
}

// stream data 
export function streamData(streamData) {
    return (dispatch) => {
        return dispatch({ type: actionTypes.STREAM_DATA, streamData });
    };
}

// channel details
export function channelDetails(channelDetails) {
    return (dispatch) => {
        return dispatch({ type: actionTypes.CHANNEL_DETAILS, channelDetails });
    };
}