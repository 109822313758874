import React, { useRef, useState } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../../../services/auth.service";
import styles from "./ForgotPassword.module.scss";

function ForgotPassword(props) {
  const { setLoginMode } = props;

  const required = (value) => {
    if (!value) {
      return (
        <div className={styles.errorMsg}>
          <p>This field is required!</p>
        </div>
      );
    }
  };

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  const onSubmit = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.requestResetPassword(email).then(
        (response) => {
          setLoading(false);
          setMessage(`Password reset request sent to ${email}`);
          setMessageType("success");
        },
        (error) => {
          setLoading(false);
          setMessage('No Account Associated with this Email');
          setMessageType("error");
        }
      );
    } else {
      setLoading(false);
    }
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  return (
    <div className={styles.forgotPasswordModal}>
      <Form onSubmit={onSubmit} ref={form} method={"POST"}>
        <Input
          type="text"
          name="email"
          value={email}
          onChange={onChangeEmail}
          validations={[required]}
          placeholder="Your Email"
          TextField
        />

        <div className={styles.forgotPasswordBtnWrapper}>
          <button className={styles.resetBtn} disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Reset My Password
          </button>
          <p onClick={setLoginMode}>Back to Login</p>
        </div>

        {message && (
          <div className={styles.errorMsg}>
            <p>{message}</p>
          </div>
        )}

        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
}

export default ForgotPassword;
