import Globals from "../utils/globalUtils";
import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{
        display: "grid",
        gridTemplateRows: "2fr .75fr",
        alignItems: "center",
        width: "100%",
      }}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          style={{
            color: "white",
            marginRight: "10px",
            border: "none",
            height: "30px",
            width: "160px",
            borderRadius: "10px",
            fontSize: "15px",
            cursor: "pointer",
            boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.5)",
            backgroundImage:
              "linear-gradient(90deg, rgba(254, 96, 31, 1) 0%, rgba(234, 20, 65, 1) 52%, rgba(185, 18, 156, 1) 100%)",
          }}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

const getConnect = (channelID) => {
  return Globals.sendRequest({
    url: `/api/payments/connect/?q=${channelID}`,
    method: "GET",
  });
};

const postConnect = (channel_id) => {
  return Globals.sendRequest({
    url: "/api/payments/connect/",
    method: "POST",
    data: {channel_id: channel_id},
  });
};

const createSubscription = (access, channel_id) => {
  return Globals.sendRequest({
    url: "/api/payments/subscription/",
    method: "POST",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    data: { channel_id: channel_id },
  });
};

const deleteSubscription = (access, id) => {
  return Globals.sendRequest({
    url: `/api/payments/subscription/?q=${id}`,
    method: "DELETE",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
  });
};

const getSubscription = (access) => {
  return Globals.sendRequest({
    url: "/api/payments/subscription/",
    method: "gET",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
  });
};

const getCustomerPaymentDetails = () => {
  return Globals.sendRequest({
    url: "/api/payments/customer-payment-details/",
    method: "GET",
  });
};

const patchCustomerPaymentDetails = (access, id) => {
  return Globals.sendRequest({
    url: "/api/payments/customer-payment-details/",
    method: "PATCH",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    data: { 'payment_method': id },
  });
};

const deleteCustomerPaymentDetails = (access, id) => {
  return Globals.sendRequest({
    url: `/api/payments/customer-payment-details/?q=${id}`,
    method: "DELETE",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
  });
};

const postCustomerPaymentDetails = () => {
  return Globals.sendRequest({
    url: "/api/payments/setup-intent/",
    method: "POST",
    data: '',
  });
};

const getPaymentTransactions = (access) => {
  return Globals.sendRequest({
    url: "/api/payments/transactions/",
    method: "GET",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
  });
};

const postPaymentLinksOnboard = (access, channelID, returnUrl) => {
  return Globals.sendRequest({
    url: "/api/payments/links/onboard/",
    method: "POST",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    data: { channelId: channelID, returnUrl: returnUrl },
  });
};

const postPaymentLinksLogin= (access, channelID, returnUrl) => {
  return Globals.sendRequest({
    url: "/api/payments/links/login/",
    method: "POST",
    Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    data: { channelId: channelID, returnUrl: returnUrl },
  });
};

const getSubscribedChannels = (access) => {
  return Globals.sendRequest({
    url: `/api/payments/subscription/channels`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
  });
};

const getSubscribedRecordings = (access) => {
  return Globals.sendRequest({
    url: `/api/payments/subscription/recordings`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
    },
  });
};

const PaymentService = {
  getConnect,
  postConnect,
  createSubscription,
  getSubscription,
  deleteSubscription,
  getCustomerPaymentDetails,
  patchCustomerPaymentDetails,
  deleteCustomerPaymentDetails,
  postCustomerPaymentDetails,
  getPaymentTransactions,
  postPaymentLinksOnboard,
  postPaymentLinksLogin,
  CheckoutForm,
  getSubscribedChannels,
  getSubscribedRecordings,
};

export default PaymentService;
