import Globals from "../utils/globalUtils";
import { hideLoader, showLoader } from "../utils/loader";

const getChannels = () => {
    return Globals.sendRequest({
        url: "/api/livestream/user_channels/",
        method: "GET",
    });
};

const getChannelDetials = (channelId) => {
    return Globals.sendRequest({
        url: "/api/livestream/channel/?q=" + channelId,
        method: "GET",
    })
}

const getChannelDetailsV2 = (channelID) => {
    return Globals.sendRequest({
        url: "/api/v2/channels/" + channelID,
        method: "GET",
    })
}

const postChannel = (data, access) => {
    showLoader();
    return Globals.sendRequest({
      url: "/api/livestream/channel/",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
      },
      data: data,
    }).finally(() => {
      hideLoader();
    });
}

const getTags = (q = '') => {
    return Globals.sendRequest({
        url: `/api/livestream/tags/?q=${q}`,
        method: "GET"
    })
}

const postTags = (tagName) => {
    if (!tagName) return null;
    return Globals.sendRequest({
        url: `/api/livestream/tags/`,
        method: "POST",
        data: {
            "tag": tagName
        }
    })
}

const startStream = (data) => {
    if (!data.name) return null;
    return Globals.sendRequest({
        url: `/api/livestream/stream/`,
        method: "POST",
        data: {
            ...data
        }
    })
}

const uploadChannelAvatar = (image) => {
    showLoader();
    const formData = new FormData();
    formData.append("avatar", image);
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/livestream/upload/",
            method: "POST",
            data: formData,
        }).then((profile) => {
            hideLoader();
            resolve(profile);
        }).catch((error) => {
            hideLoader();
            reject(error);
        });
    });
};

const getChannelAvatar = (imageId) => {
    return new Promise((resolve, reject) => {
        if (!!imageId)
            Globals.sendRequest({
                url: "/api/livestream/upload/?q=" + imageId,
                method: "GET"
            }).then((response) => {
                if (response.imageUrl)
                    resolve(response.imageUrl)
            }).catch((error) => {
                reject(error);
            });
        else resolve(null)
    })
}

const inviteCollaborator = (data) => {
    showLoader();
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/livestream/invite/",
            method: "POST",
            data: {
                ...data
            }
        }).then((profile) => {
            hideLoader();
            resolve(profile);
        }).catch((error) => {
            hideLoader();
            reject(error);
        });
    })
}

const searchUsers = (data) => {
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/search/?suggestions=true" + "&q=" + data,
            method: "GET"
        }).then((response) => {
            if (response.people)
                resolve(response.people)
        }).catch((error) => {
            reject(error);
        });
    })
}

const ackInvite = (data) => {
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/livestream/invite/",
            method: "PUT",
            data: {
                ...data
            }
        }).then((profile) => {
            resolve(profile);
        }).catch((error) => {
            reject(error);
        });
    })
}

const getCollaborators = (channelId) => {
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/livestream/invite?q=" + channelId,
            method: "GET"
        }).then((channelAdminList) => {
            resolve(channelAdminList);
        }).catch((error) => {
            reject(error);
        });
    })
}

const getUserList = (page = 1, search) => {
    return new Promise((resolve, reject) => {
        Globals.sendRequest({
            url: "/api/users/user-search?page=" + page + "&search=" + search,
            method: "GET"
        }).then((userList) => {
            resolve(userList);
        }).catch((error) => {
            reject(error);
        });
    })
}

const deleteCollaboratorAPI = (userId, channelId) => {
    try {
        return new Promise((resolve, reject) => {
            Globals.sendRequest({
                url: "/api/livestream/invite/",
                method: "DELETE",
                data: {
                    'receiver_id': userId,
                    'channel_id': channelId
                }
            }).then((resp) => {
                resolve(resp);
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (err) {
        console.error('CAUGH IN TRY')
    }
}

const patchChannelDetails = (data) => {
    try {
        showLoader();
        return new Promise((resolve, reject) => {
            Globals.sendRequest({
                url: "/api/livestream/channel/",
                method: "PATCH",
                data: {
                    ...data
                }
            }).then((resp) => {
                resolve(resp);
            }).catch((error) => {
                reject(error);
            }).finally(() => {
                hideLoader();
            });
        })
    } catch (err) {
        console.error('CAUGH IN TRY')
    }
}

const getStream = (channelID, access) => {
    fetch(`https://politix.tv/api/livestream/stream/?q=${channelID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
      },
    })
      .then((response) => {
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
}

const createStream = (channelID, access, streamDescription, streamTitle, streamTags) => {
    if (!getStream()) {
      fetch(`https://politix.tv/api/livestream/stream/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
        },
        body: JSON.stringify({
          stream_desc: `${streamDescription}`,
          channel_id: `${channelID}`,
          name: `${streamTitle}`,
          profiles: [
            {
              name: "720p",
              bitrate: 2000000,
              fps: 30,
              width: 1280,
              height: 720,
            },
            {
              name: "480p",
              bitrate: 1000000,
              fps: 30,
              width: 854,
              height: 480,
            },
            {
              name: "360p",
              bitrate: 500000,
              fps: 30,
              width: 640,
              height: 360,
            },
          ],
          tags: `${streamTags}`,
        }),
      })
        .then((response) => {
          getStream();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } 
}

const StreamerService = {
  postTags,
  getTags,
  ackInvite,
  getChannels,
  startStream,
  searchUsers,
  postChannel,
  getChannelAvatar,
  inviteCollaborator,
  uploadChannelAvatar,
  getChannelDetials,
  getCollaborators,
  getUserList,
  deleteCollaboratorAPI,
  patchChannelDetails,
  getStream,
  createStream,
  getChannelDetailsV2,
};

export default StreamerService;
