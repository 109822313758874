import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  userProfile: {},
  dispatch: (...params) => {},
};

const AppContext = createContext(initialState);

const Store = (children) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  function getChildNodes() {
    return Object.values(children);
  }
  return (
    <div>
      <AppContext.Provider value={{ ...state, dispatch: dispatch }}>
        {getChildNodes().map((child) => child)}
      </AppContext.Provider>
    </div>
  );
};

export { AppContext };
export default Store;
