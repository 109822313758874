import { createStore, applyMiddleware } from 'redux';
import { reducers } from '../redux/reducer';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { api } from './api';
import { Toast } from './toast';

let createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
let store = createStoreWithMiddleware(reducers);

if (process.env.NODE_ENV !== "production") {
    store = createStoreWithMiddleware(
        reducers,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
}

const history = require('history').createBrowserHistory({});
export {
    store,
    history
};


// export const store = createStore(reducers, applyMiddleware(thunk.withExtraArgument({ api, Toast }), logger));
