import React, {useState, useContext, useEffect} from "react";
import styles from "./SidebarRight.module.scss"; 
import { Link, useNavigate } from "react-router-dom";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { AppContext } from "../../store/store";
import LivestreamService from "../../sevices2/LivestreamServices";

const SidebarRight = ({darkMode}) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [trendingStreamers, setTrendingStreamers] = useState([]);
  const [trendingTopics, setTrendingTopics] = useState([]);

  const { userProfile } = useContext(AppContext);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  const history = useNavigate();
  const handleSidebarItemClick = (route) => {
    history(route);
  };

  const getTrendingTopics = () => {
    LivestreamService.getPopularCategories()
      .then((response) => {
        setTrendingTopics(response.slice(0, 6));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTrendingStreamers = () => {
    LivestreamService.getPopularStreamers()
      .then((response) => {
        setTrendingStreamers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTrendingStreamers();
    getTrendingTopics();
  }, []);

  return (
    <>
      <div className={styles.containerPadding}>
        <div
          className={styles.sidebar}
          style={{ backgroundColor: darkMode ? "#151521" : "" }}
        >
          <div
            className={styles.sidebarTitle}
            style={{ paddingTop: "18px", color: darkMode ? "#FFFFFF" : "" }}
          >
            <ShowChartIcon
              style={{
                color: "$newDarkBlue",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Top Streamers
          </div>
          <ol className={styles.trendingStreamersList}>
            {trendingStreamers.map((streamer, index) => (
              <li
                className={styles.trendingStreamer}
                style={{ color: darkMode ? "#8e8d98" : "" }}
                onClick={() =>
                  handleSidebarItemClick(`/channel/${streamer.id}`)
                }
              >
                <span
                  className={styles.listNumber}
                  style={{
                    color: darkMode ? "#FFFFFF" : "",
                  }}
                >
                  {index + 1}
                </span>
                {streamer.name}
              </li>
            ))}
          </ol>
          <div
            className={styles.sidebarTitle}
            style={{
              paddingTop: "10px",
              paddingBottom: "15px",
              color: darkMode ? "#FFFFFF" : "",
            }}
          >
            <ShowChartIcon
              style={{
                color: "$newDarkBlue",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Trending Topics
          </div>
          <ul className={styles.trendingTopicsList}>
            {trendingTopics.map((topic) => (
              <Link
                to={`/search?q=${topic.tag_name}`}
                key={topic.tag_name}
                className={styles.removeLinkStyling}
              >
                <li className={styles.trendingTopic}>{topic.tag_name}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SidebarRight;
