import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useContext, useRef, useState } from "react";
import { FacebookProvider, Login } from "react-facebook";
import ReactGA from "react-ga";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import facebookIcon from "../../../assets/icons/fbIcon.png";
import googleIcon from "../../../assets/icons/googleIcon.png";
import { channelLists } from "../../../redux/actions/streamer";
import { userProfileData } from "../../../redux/actions/user";
import AuthService from "../../../services/auth.service";
import { ACTIONS } from "../../../store/reducer";
import { AppContext } from "../../../store/store";
import styles from "./Login.module.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

ReactGA.initialize("UA-219193792-1");

function LoginFun(props) {
  const { handleForgetPassword, setSignUpMode } = props;

  const history = useNavigate();
  const { dispatch } = useContext(AppContext);

  const required = (value) => {
    if (!value) {
      return (
        <div className={styles.errorMsg}>
          <p>This field is required!</p>
        </div>
      );
    }
  };

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordVisible, setPasswordVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    if (email !== "")
      setErrorMessage((prev) => {
        return {
          ...prev,
          email: undefined,
        };
      });
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    if (password !== "")
      setErrorMessage((prev) => {
        return {
          ...prev,
          password: undefined,
        };
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();
    if (email !== "" && password !== "") {
      if (checkBtn.current.context._errors.length === 0) {
        AuthService.login({ email, password }).then(
          async (user) => {
            props.userProfileData(user);
            dispatch({ type: ACTIONS.SET_USER, payload: user });
            // await StreamerService.getChannels().then((data) => props.channelLists(data && data.channels));
            // history("/explore/");
            window.gtag("event", "login", {
              event_category: "access",
              event_label: "login",
            });

            ReactGA.event({
              category: "User",
              action: "Logged In",
            });

            ReactGA.ga("set", "Logged-In", 1);
            ReactGA.ga("set", "userId", user.id); // Set the user ID using signed-in user_id.
            ReactGA.event({
              category: "User",
              action: "Logged In",
            });
          },
          (error) => {
            let resMessage = error.message;
            if (!resMessage) {
              resMessage = Object.values(error).join(" ");
            }
            setLoading(false);
            setMessage(resMessage);
          }
        );
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (email == "") {
        setErrorMessage({
          email: "Email is Required!",
        });
      }
      if (password == "")
        setErrorMessage((prev) => {
          return { ...prev, password: "Password is Required!" };
        });
    }
  };

  const toggleIsPasswordShowValue = () => {
    setPasswordVisibility((prevState) => !prevState);
  };

  const handleResponse = (data) => {
    let loginRequest = {
      logintype: "fb",
      email: data?.profile?.email,
      auth_token: data?.tokenDetail?.accessToken,
    };
    setLoading(true);
    AuthService.login({ ...loginRequest }).then(
      async (user) => {
        props.userProfileData(user);
        dispatch({ type: ACTIONS.SET_USER, payload: user });
        // await StreamerService.getChannels().then((data) => props.channelLists(data && data.channels));
        history("/explore/");
        ReactGA.event({
          category: "User",
          action: "Logged In",
        });
        ReactGA.ga("set", "Logged-In", 1);
        ReactGA.ga("set", "userId", user.id); // Set the user ID using signed-in user_id.
        window.gtag("event", "login", {
          event_category: "access",
          event_label: "login",
        });
      },
      (error) => {
        let resMessage = error.message;
        if (!resMessage) {
          resMessage = Object.values(error).join(" ");
        }
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleError = (error) => {
    console.log({ error });
  };

  const responseGoogle = (data) => {
    // console.log("Google Login Response -----> ", data);
    let loginRequest = {
      logintype: "google",
      email: data?.profileObj?.email,
      auth_token: data?.tokenId,
    };
    setLoading(true);
    AuthService.login({ ...loginRequest }).then(
      async (user) => {
        props.userProfileData(user);
        dispatch({ type: ACTIONS.SET_USER, payload: user });
        // await StreamerService.getChannels().then((data) => props.channelLists(data && data.channels));
        history("/explore/");
        window.gtag("event", "login", {
          event_category: "access",
          event_label: "login",
        });
        ReactGA.ga("set", "Logged-In", 1);
        ReactGA.ga("set", "userId", user.id); // Set the user ID using signed-in user_id.
        ReactGA.event({
          category: "User",
          action: "Logged In",
        });
      },
      (error) => {
        let resMessage = error.message;
        if (!resMessage) {
          resMessage = Object.values(error).join(" ");
        }
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const responseErrorGoogle = (response) => {
    alert(
      "Google Login is not available at the moment, Please try after sometime."
    );
  };

  const twitterAuthHandler = (err, data) => {
    console.log(err, data);
  };

  return (
    <>
      {/* <div className={styles.socialLogin}>
        <p>Log In With</p>
        <FacebookProvider appId="522317156041808">
          <Login
            scope="email"
            onCompleted={handleResponse}
            onError={handleError}
          >
            {({ loading, handleClick, error, data }) => (
              <img onClick={handleClick} src={facebookIcon} />
            )}
          </Login>
        </FacebookProvider>

        <GoogleLogin
          clientId="1089250428004-bk1bod1p0g45aigil6i00qu449k651kt.apps.googleusercontent.com"
          render={(renderProps) => (
            <img
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              src={googleIcon}
            />
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseErrorGoogle}
          cookiePolicy={"single_host_origin"}
        />

        <TwitterLogin
          buttonTheme={"light_short"}
          authCallback={twitterAuthHandler}
          className={styles.twitterBtn}
          consumerKey={"LChjGaEgjDZMWuunZksE3xF3Y"}
          consumerSecret={"CeDEgOp4WB33hFbtcswAOoxJb9upici2gqipxpygTqaULhdCWE"}
          children={<img className={styles.twitterIcon} src={twitterIcon} />}
        />
        <img src={twitterIcon} />
      </div> */}
      <div className={styles.loginModal} style={{marginTop: '30px'}}>
        <Form onSubmit={handleLogin} ref={form}>
          <div className={styles.loginInputWrapper}>
            <Input
              className={styles.userNameInput}
              autoFocus
              type="text"
              name="email"
              value={email}
              onChange={onChangeEmail}
              // validations={[required]}
              placeholder="Email/Username"
            />
            {errorMessage && errorMessage.email !== "" && (
              <div className={styles.errorMsg}>
                <p>{errorMessage.email}</p>
              </div>
            )}
            <Input
              type={passwordVisible ? "text" : "password"}
              name="password"
              value={password}
              onChange={onChangePassword}
              placeholder="Password"
            />
            <div className={styles.eyeIcon} onClick={toggleIsPasswordShowValue}>
              {passwordVisible ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
            </div>
            {errorMessage && errorMessage.password !== "" && (
              <div className={styles.errorMsg}>
                <p>{errorMessage.password}</p>
              </div>
            )}
            <a className={styles.forgotPassword} onClick={handleForgetPassword}>
              Forgot Password?
            </a>
            <div className={styles.loginBtnWrapper}>
              <button disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Login
              </button>
              <p>
                Don't have an account yet ?{" "}
                <span onClick={setSignUpMode}>Join</span>
              </p>
            </div>
          </div>
          {message && (
            <div className={styles.errorMsg}>
              <p>{message}</p>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userProfileData,
      channelLists,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginFun)
);
